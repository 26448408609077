import { StateCreator, create } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MappingOpenItemStateType } from "@mobile/types/types";
import { CHARTS } from "@modules/financialplan/constants";

interface AppState {
  isPoFExperience: boolean | undefined;
  setIsPoFExperience: (isPoFExperience: boolean | undefined) => void;
  isOnboardingCompleted: boolean;
  setIsOnboardingCompleted: (isOnboardingCompleted: boolean) => void;
  activeNavBarScreen: string;
  setActiveNavBarScreen: (path: string) => void;
  activeNavBarScreenName: string;
  setActiveNavBarScreenName: (name: string) => void;
  currentSpendingView: string;
  setCurrentSpendingView: (view: string) => void;
  isSideMenuBarOpen: boolean;
  setIsSideMenuBarOpen: (isSideMenuBarOpen: boolean) => void;
  mappingOpenItemState: MappingOpenItemStateType;
  setMappingOpenItemState: (
    mappingOpenItemState: MappingOpenItemStateType
  ) => void;
  isAdvisor: boolean;
  setIsAdvisor: (isAdvisor: boolean) => void;
  currentChart: string;
  setCurrentChart: (currentChart: string) => void;
  reset: () => void;
  isScheduleOpen: boolean;
  setIsScheduleOpen: (isScheduleOpen: boolean) => void;
  setScheduleCalendlyUrl: (scheduleCalendlyUrl: string | null) => void;
  scheduleCalendlyUrl: string | null;
  showFileNamingModal: boolean;
  setShowFileNamingModal: (showFileNamingModal: boolean) => void;
  fileNamingModalFileName: string;
  setFileNamingModalFileName: (fileNamingModalFileName: string) => void;
  fileNamingModalFileType: string;
  setFileNamingModalFileType: (fileNamingModalFileType: string) => void;
  isFileUploading: boolean;
  setIsFileUploading: (isUploading: boolean) => void;
  showReferralBanner: boolean;
  setShowReferralBanner: (show: boolean) => void;
  isOpenTalkToAnAdvisorModal: boolean;
  setIsOpenTalkToAnAdvisorModal: (isOpen: boolean) => void;
  createdByAdvisor: boolean | undefined;
  setCreatedByAdvisor: (createdByAdvisor: boolean | undefined) => void;
}

type SidebarPersist = (
  config: StateCreator<AppState>,
  options: PersistOptions<AppState>
) => StateCreator<AppState>;

const initialState = {
  isPoFExperience: undefined,
  isOnboardingCompleted: true,
  activeNavBarScreen: "360° View",
  activeNavBarScreenName: "DashboardsOverviewScreen",
  currentSpendingView: "transactions",
  isSideMenuBarOpen: false,
  mappingOpenItemState: {},
  isAdvisor: false,
  currentChart: CHARTS.PROJECTED_FINANCIAL_PLAN,
  isScheduleOpen: false,
  showFileNamingModal: false,
  fileNamingModalFileName: "",
  fileNamingModalFileType: "",
  isFileUploading: false,
  showReferralBanner: false,
  scheduleCalendlyUrl: null,
  createdByAdvisor: undefined,
};
const useStore = create<AppState>(
  (persist as unknown as SidebarPersist)(
    (set) => ({
      ...initialState,
      setIsPoFExperience: (isPoFExperience: boolean | undefined) =>
        set({ isPoFExperience }),
      setScheduleCalendlyUrl: (scheduleCalendlyUrl) =>
        set({ scheduleCalendlyUrl }),
      setIsOnboardingCompleted: (isOnboardingCompleted) =>
        set({ isOnboardingCompleted }),
      setActiveNavBarScreen: (name: string) =>
        set({ activeNavBarScreen: name }),
      setActiveNavBarScreenName: (name: string) =>
        set({ activeNavBarScreenName: name }),
      setCurrentSpendingView: (view: string) =>
        set({ currentSpendingView: view }),
      setIsSideMenuBarOpen: (isSideMenuBarOpen: boolean) =>
        set({ isSideMenuBarOpen: isSideMenuBarOpen }),
      setMappingOpenItemState: (
        mappingOpenItemState: MappingOpenItemStateType
      ) => set({ mappingOpenItemState: mappingOpenItemState }),
      setIsAdvisor: (isAdvisor: boolean) => set({ isAdvisor }),
      setCurrentChart: (currentChart: string) => set({ currentChart }),
      setIsScheduleOpen: (isScheduleOpen: boolean) => set({ isScheduleOpen }),
      setShowFileNamingModal: (show) => set({ showFileNamingModal: show }),
      setFileNamingModalFileName: (name) =>
        set({ fileNamingModalFileName: name }),
      setFileNamingModalFileType: (type) =>
        set({ fileNamingModalFileType: type }),
      setIsFileUploading: (isFileUploading) => set({ isFileUploading }),
      reset: () => set(initialState),
      setShowReferralBanner: (show) => set({ showReferralBanner: show }),
      isOpenTalkToAnAdvisorModal: false,
      setIsOpenTalkToAnAdvisorModal: (show) =>
        set({
          isOpenTalkToAnAdvisorModal: show,
        }),
      createdByAdvisor: undefined,
      setCreatedByAdvisor: (createdByAdvisor) => set({ createdByAdvisor }),
    }),
    {
      name: "app-state-storage",
      getStorage: () => AsyncStorage,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              ![
                "mappingOpenItemState",
                "isOnboardingCompleted",
                "currentChart",
                "isScheduleOpen",
                "showReferralBanner",
              ].includes(key)
          )
        ) as AppState,
    }
  )
);

export { useStore };
export default useStore;
