import request from "@common/request";
import { FingerprintScore } from "@modules/fingerprint/types";
import { Account, Clients, MxAccount, Profile } from "@modules/forme/types";
import { PaginatedResponse, OrionClient } from "@modules/forme/types";
import { useQuery } from "react-query";
import { BaseClientInfo, ClientInfo, ClientInfoComputedFields } from "./types";
import { useMemo } from "react";
import { computedFields } from "./helper";

const useAdvisors = () => {
  return useQuery("advisors", () =>
    request<Forme.PaginatedResponse<Forme.Advisor>>("GET", "/api/advisor")
  );
};

const useFormeHouseholds = () => {
  return useQuery("formeHouseholds", () =>
    request<Forme.PaginatedResponse<Forme.HouseholdWithClientUsers>>(
      "GET",
      "/api/advisor/households?SOURCE=FORME"
    )
  );
};

const useOrionHouseholds = () => {
  return useQuery("orionHouseholds", () =>
    request<PaginatedResponse<OrionClient>>(
      "GET",
      "/api/advisor/households?SOURCE=ORION"
    )
  );
};

const useHouseholdClients = (
  { householdId }: { householdId: string },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any
) => {
  return useQuery(["householdClients", householdId], () =>
    request<Clients>("GET", "/api/advisor/clients", { householdId }, options)
  );
};

const useClients = ({ offset, limit }: { offset: number; limit: number }) => {
  return useQuery(["clients", offset], () =>
    request<{
      clients: Forme.ClientUser[];
      totalCount: number;
    }>("GET", "/api/advisor/clients", { offset, limit })
  );
};

const useClientProfile = ({ clientId }: { clientId: string | undefined }) => {
  return useQuery(["clientProfile", clientId], () =>
    request<Profile>("GET", `/api/advisor/clients/${clientId}/profile`, {
      enabled: !!clientId,
    })
  );
};

const useClientByUserId = ({ userId }: { userId?: string }) => {
  return useQuery(
    ["clients", userId],
    () =>
      request<{
        client: Forme.ClientUser;
      }>("GET", `/api/advisor/clients?userId=${userId}`, {}, {}, true),
    {
      enabled: !!userId,
    }
  );
};

const useClientById = ({ clientId }: { clientId: string }) => {
  const { data, ...result } = useQuery(
    ["clientById", clientId],
    () => request<BaseClientInfo>("GET", `/api/advisor/clients/${clientId}`),
    { enabled: !!clientId }
  );

  const clientData: ClientInfo | undefined = useMemo(() => {
    if (data) {
      const fields = Object.keys(
        computedFields
      ).reduce<ClientInfoComputedFields>((fields, k) => {
        const key = k as keyof ClientInfoComputedFields;
        return {
          ...fields,
          [key]: computedFields[key](data),
        };
      }, {} as ClientInfoComputedFields);
      return {
        ...fields,
        ...data,
      };
    }
  }, [data]);

  return {
    data: clientData,
    ...result,
  };
};

const useClientFingerprintScores = ({ clientId }: { clientId: string }) =>
  useQuery(
    ["clientFingerprintScores", clientId],
    () =>
      request<FingerprintScore[]>(
        "GET",
        `/api/advisor/clients/${clientId}/fingerprint-scores`
      ),
    {
      enabled: !!clientId,
    }
  );

const useHouseholdTasks = (householdId: string) => {
  return useQuery(
    ["tasks", householdId],
    () =>
      request<Forme.EnrichedTask[]>(
        "GET",
        `/api/advisor/households/${householdId}/tasks`
      ),
    {
      enabled: !!householdId,
    }
  );
};
const useHouseholdOrionAccounts = (householdId: string | undefined) => {
  return useQuery(
    ["orionAccounts", householdId],
    () =>
      request<Account[]>(
        "GET",
        `/api/advisor/households/${householdId}/accounts?type=orion`
      ),
    {
      enabled: !!householdId,
    }
  );
};

const useHouseholdMxAccounts = (householdId: string | undefined) => {
  return useQuery(
    ["mxAccounts", householdId],
    () =>
      request<MxAccount[]>(
        "GET",
        `/api/advisor/households/${householdId}/accounts?type=mx`
      ),
    {
      enabled: !!householdId,
    }
  );
};

export {
  useAdvisors,
  useFormeHouseholds,
  useOrionHouseholds,
  useClients,
  useClientByUserId,
  useClientFingerprintScores,
  useClientById,
  useHouseholdOrionAccounts,
  useHouseholdClients,
  useHouseholdTasks,
  useClientProfile,
  useHouseholdMxAccounts,
};
