/** @type {import('tailwindcss').Config} */

module.exports = {
  theme: {
    extend: {
      screens: {
        "sm": "640px",
        "md": "768px",
        "lg": "1024px",
        "xl": "1280px",
        ["2xl"]: "1536px",
        // Alternative (Website) Breakpoints
        "tablet": "600px",
        "desktop": "1048px",
        "desktop-lg": "1280px",
      },
      opacity: {
        "03": ".03",
      },
      blur: {
        xs: "1px",
      },
      boxShadow: {
        sm: "0px 1px 2px 0px rgba(0,0,0,0.06), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
        inner: "0px 4px 4px 0px rgba(64, 57, 108, 0.34 inset)",
      },
      transitionProperty: {
        width: "width, min-width, max-width",
        col: "grid-column",
      },
      spacing: {
        6.5: "1.625rem",
        7.5: "1.875rem",
        13: "3.25rem",
        15: "3.75rem",
        18: "4.5rem",
        25: "6.25rem",
        30: "7.5rem",
        75: "18rem",
        76: "19rem",
      },
      rounded: {
        round: "1.25rem",
      },
      strokeWidth: {
        default: "1.67px",
      },
      minHeight: (theme) => ({
        ...theme("spacing"),
      }),
      maxHeight: (theme) => ({
        ...theme("spacing"),
      }),
      minWidth: (theme) => ({
        ...theme("spacing"),
      }),
      maxWidth: (theme) => ({
        ...theme("spacing"),
      }),
      fontSize: {
        // LEGACY - DEPRECATED
        "heading-1": ["55px", "56px"],
        "heading-2": ["44px", "48px"],
        "heading-3": ["35px", "40px"],
        "heading-3.5": ["32px", "35px"],
        "heading-4": ["28px", "32px"],
        "heading-5": ["22.5px", "24px"],
        "heading-6": ["18px", "20px"],
        "label-xs": ["9.2px", "12px"],
        "label-sm": ["11.5px", "12px"],
        "label-legend": ["11.5px", "16px"],
        "label-base": ["14.4px", "16px"],
        "label-body": ["14.4px", "18px"],
        "label-lg": ["18px", "24px"],
        // New
        "display": ["96px", "100%"],
        "title-2xl": ["3rem", "3rem"],
        "title-xl": ["2.25rem", "2.5rem"],
        "title-lg": ["1.875rem", "2.25rem"],
        "title-md": ["1.5rem", "2rem"],
        "title-sm": ["1.25rem", "1.5rem"],
        "title-xs": ["1rem", "1.5rem"],
        "body-2xl": ["32px", "140%"],
        "body-xl": ["1.25rem", "1.75rem"],
        "body-lg": ["1.125rem", "1.5rem"],
        "body-md": ["1rem", "1.5rem"],
        "body": ["1rem", "1.5rem"], // Alias
        "body-sm": ["0.875rem", "1.25rem"],
        "body-xs": ["0.75rem", "1.125rem"],
        "button-sm": ["12px", "16px"],
        "button-md": ["16px", "24px"],
        "button-lg": ["18px", "24px"],
        "modal-title": ["18px", "24px"],
        "input-label": ["16px", "24px"],
        "input-helper": ["16px", "24px"],
        "input-error": ["16px", "24px"],
        "input-placeholder": ["16px", "22.4px"],
      },
    },
    fontFamily: {
      sans: ["RedHat_Text", "sans-serif"],
      display: ["RedHat_Text", "sans-serif"],
      body: ["RedHat_Text", "sans-serif"],
      serif: ["serif"],
      mono: ["RedHat_Mono"],
      italic: ["Summerford_Italic"],
    },
    textColor: ({ theme }) => ({
      ...theme("colors"),
      "primary": theme("colors.green.1"),
      "secondary": theme("colors.green.5"),
      "disabled": theme("colors.gray.400"),
      "light": "white",
      "link": theme("colors.blue.600"),
      "danger": theme("colors.red.500"),
      "success": theme("colors.green.500"),
      "input-placeholder": theme("colors.gray.400"),
      "input-primary": theme("colors.gray.700"),
      "input-disabled": theme("colors.gray.400"),
      "input-danger": theme("colors.red.500"),
    }),
    stroke: ({ theme }) => ({
      ...theme("colors"),
      link: theme("textColor.link"),
    }),
    borderColor: ({ theme }) => ({
      ...theme("colors"),
      "primary": theme("colors.gray.200"),
      "disabled": theme("colors.gray.300"),
      "control-primary": theme("colors.gray.400"),
      "control-primary--hover": theme("colors.gray.500"),
      "control-primary--active": theme("colors.gray.500"),
      "control-danger": theme("colors.red.600"),
      "control-disabled": theme("colors.gray.200"),
      "input-primary": theme("colors.gray.400"),
      "input-primary--hover": theme("colors.gray.500"),
      "input-primary--active": theme("colors.gray.600"),
      "input-danger": theme("colors.red.600"),
    }),
    backgroundColor: ({ theme }) => ({
      ...theme("colors"),
      "primary": "white",
      "secondary": theme("colors.green.50"),
      "tertiary": theme("colors.green.40"),
      "surface": "white",
      "danger": theme("colors.red.500"),
      "danger-light": theme("colors.red.50"),
      "disabled": theme("colors.gray.100"),
      "overlay": "rgba(0,0,0,0.25)",
      "control": theme("colors.green.3"),
      "control-primary": theme("colors.green.3"),
      "control-primary--hover": theme("colors.green.2"),
      "control-primary--active": theme("colors.green.1"),
      "control-danger": theme("colors.red.600"),
      "control-disabled": theme("colors.gray.200"),
      "input-primary": theme("colors.white"),
      "input-disabled": theme("colors.gray.100"),
    }),
    padding: ({ theme }) => ({
      ...theme("spacing"),
      "input-pb": theme("spacing.6"),
      "input-label-pb": theme("spacing.1"),
      "input-helper-pt": theme("spacing.1"),
      "control-pb": theme("spacing.2"),
      "form-question-pb": theme("spacing.5"),
      "form-question-block-pb": theme("spacing.8"),
      "form-question-block-pl": theme("spacing.8"),
      "form-review-block-py": theme("spacing.8"),
      "form-detail-pl": theme("spacing.8"),
    }),
    gap: ({ theme }) => ({
      ...theme("spacing"),
      "control-gap": theme("spacing.4"),
    }),
    colors: {
      "data": {
        blue: "#0053CF",
        red: "#D85C46",
        green: "#51B867",
        yellow: "#F1AE00",
        lightblue: "#9AC6FF",
      },
      "transparent": "transparent",
      "inherit": "inherit",
      "offwhite": "#F5FAF6",
      "white": "#FFFFFF",
      "black": "#000000",
      "gray": {
        50: "#FAF8F8",
        100: "#F0EEEE",
        200: "#E0DFDF",
        300: "#C7C7C7",
        400: "#A8A8A8",
        500: "#909090",
        600: "#757474",
        700: "#585757",
        800: "#403F3F",
        900: "#262626",
      },
      /* Primitive Colors */
      "blue": {
        /* Brand Colors */
        1: "#0257BB",
        2: "#0569DF",
        3: "#0075FF",
        /* */
        50: "#E4EAF8",
        100: "#BBD5FF",
        200: "#9AC6FF",
        300: "#75A9FF",
        400: "#348BFC",
        500: "#256DF1",
        600: "#0053CF",
        700: "#0042A6",
        800: "#00327D",
        900: "#002254",
      },
      "green": {
        /* Brand Colors */
        1: "#04260E",
        2: "#053313",
        3: "#064018",
        4: "#084C1C",
        5: "#095921",
        /* */
        30: "#F1FCF4",
        40: "#FCFEFC",
        50: "#DAF2E1",
        100: "#AEDBB7",
        200: "#78C587",
        300: "#51b867",
        400: "#49A55E",
        500: "#32853E",
        600: "#287138",
        700: "#215E2F",
        800: "#1B4B26",
        900: "#14381C",
      },
      "yellow": {
        /* Brand Colors */
        1: "#776205",
        2: "#B99700",
        3: "#FFD620",
        /**/
        50: "#F8EDC4",
        100: "#FFDB84",
        200: "#FAC848",
        300: "#F1AE00",
        400: "#D18A00",
        500: "#A36905",
        600: "#925803",
        700: "#7D4C03",
        800: "#643D02",
        900: "#4B2D02",
      },
      "red": {
        50: "#FAE2DE",
        100: "#F0AB9E",
        200: "#EF8472",
        300: "#E56E5A",
        400: "#D85C46",
        500: "#C64E39",
        600: "#A73112",
        700: "#952001",
        800: "#791900",
        900: "#581300",
      },
      "teal": {
        1: "#00BD71",
        2: "#02D781",
        3: "#00FF99",
      },
      "danger": "#C64E39",
      "input-border-danger": "#A73112",
      "input-disabled": "#E8E9EB",
      "tab-bg-active": "#ECECEC",
      "tab-bg-hover": "#E7EDF7",

      /* Legacy (Deprecated) */
      "purple-1": {
        100: "#DAD9E5",
        300: "#B6B2CB",
        500: "#918CB2",
        700: "#6D6598",
        900: "#483F7E",
      },
      "purple-2": {
        100: "#E0DDF0",
        300: "#BCB7DA",
        500: "#9B92C7",
        700: "#796FB5",
        900: "#574BA3",
      },
      "purple-3": {
        100: "#E1E1F6",
        300: "#C3C2EC",
        500: "#A5A4E3",
        700: "#8786DA",
        900: "#6967D0",
      },
      "purple-4": {
        100: "#E7E5FD",
        300: "#CECCFB",
        500: "#B4B2F9",
        700: "#9B99F8",
        900: "#8380F7",
      },
      "purple-5": {
        100: "#E4E4F5",
        300: "#AFB3E1",
        500: "#6D6CC5",
        700: "#5A4EA7",
        900: "#40396C",
      },
      // teal: {
      //   100: "#EDFBF4",
      //   300: "#DCF8EA",
      //   500: "#CBF4DE",
      //   700: "#BBF1D4",
      //   900: "#ABEDCA",
      // },
      "pink": {
        100: "#FBF3F1",
        300: "#F7E7E4",
        500: "#F5DBD5",
        700: "#F1CFC8",
        900: "#EEC3BA",
      },
      // green: {
      //   100: "#E5EEEF",
      //   300: "#CBDDDF",
      //   500: "#B1CCCE",
      //   700: "#98BCBE",
      //   900: "#7FAAAE",
      // },
      // yellow: {
      //   100: "#FDF6EB",
      //   300: "#FBEDD7",
      //   500: "#F9E2C2",
      //   700: "#F7D8AD",
      //   900: "#F5CF98",
      // },
      // red: {
      //   100: "#F7E5E7",
      //   300: "#F1CBCF",
      //   500: "#EAB1B8",
      //   700: "#E497A0",
      //   900: "#DE7E89",
      // },
      "calipso": {
        100: "#F0F0FB",
        300: "#E3E3FA",
      },
    },
  },
  plugins: [require("@tailwindcss/forms"), require("@headlessui/tailwindcss")],
};
