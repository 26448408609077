import { useRouter as useNextRouter } from "next/router";
import { isString } from "lodash";
import type { UrlObject } from "url";
import useStore from "@common/state";
import {
  ClientRoutes,
  CareerStage,
  CareerStageOptions,
  themeBreakpoints,
} from "@common/constants";
import { useProfile, useProfileUpdate } from "@common/queries";
import {
  startTransition,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { resetPersistedStores } from "./utils";
import { useWindowSize } from "usehooks-ts";
import useAdvisorStore from "@modules/advisor/state";
import { useUser } from "@auth0/nextjs-auth0";
import { useSignupInfo } from "@modules/signup/queries";

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}
type Url = UrlObject | string;

const useRouter = () => {
  const router = useNextRouter();
  const { setDesiredPath } = useStore();
  return {
    ...router,
    push: (url: Url, as?: Url, options?: TransitionOptions) => {
      const promise = router.push(url, as, options);
      if (isString(url)) {
        setDesiredPath(url as string);
      }
      return promise;
    },
  };
};

const useLogout = () => {
  const {
    setIsLoggingOut,
    showLogoutDialog,
    setShowLogoutDialog,
    isLoggingOut,
  } = useStore();
  const router = useNextRouter();
  const logout = useCallback(async () => {
    setIsLoggingOut(true);
    await resetPersistedStores();
    window.heap?.resetIdentity();
    window.heap?.clearEventProperties();
    router.push(ClientRoutes.Logout);
  }, [router, setIsLoggingOut]);
  return {
    showLogoutDialog,
    isLoggingOut,
    setShowLogoutDialog,
    logout,
  };
};

const useBreakpoints = () => {
  const { width } = useWindowSize();
  return {
    isTablet: Boolean(
      width && width < themeBreakpoints.lg && width >= themeBreakpoints.md
    ),
    isDesktop: Boolean(width && width >= themeBreakpoints.lg),
    isMobile: Boolean(width && width < themeBreakpoints.sm),
    isZendeskMobile: Boolean(width && width <= 555),
  };
};

const useCareerStage = () => {
  const { mutateAsync: updateProfile } = useProfileUpdate();
  const {
    isLoading: isLoadingProfile,
    data: profile,
    refetch: refetchProfile,
  } = useProfile();

  const careerStageValue = useMemo(
    () =>
      profile?.careerStage
        ? {
            id: profile?.careerStage as CareerStage,
            value: profile?.careerStage as CareerStage,
            label:
              CareerStageOptions.find((o) => o.id === profile?.careerStage)
                ?.name ?? "",
            name:
              CareerStageOptions.find((o) => o.id === profile?.careerStage)
                ?.name ?? "",
          }
        : CareerStageOptions[0],
    [profile]
  );

  return {
    value: careerStageValue,
    options: CareerStageOptions,
    isLoading: isLoadingProfile,
    refetch: refetchProfile,
    mutateAsync: updateProfile,
  };
};

const useRouterQueryParam = (query = "ref") => {
  const router = useRouter();

  const queryParam = useMemo(() => {
    return router.query[query];
  }, [router.query, query]);

  const getQueryParamAsURLSearchParams = useCallback(
    (options: { replaceParams: boolean } = { replaceParams: false }) => {
      const { pathname, query: routerQuery } = router;
      return new URLSearchParams(
        options.replaceParams
          ? { [query]: pathname }
          : ({
              ...routerQuery,
              [query]: pathname,
            } as Record<string, string>)
      );
    },
    [router, query]
  );

  const setQueryParam = useCallback(async () => {
    const { pathname } = router;
    const params = getQueryParamAsURLSearchParams();
    await router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  }, [router, getQueryParamAsURLSearchParams]);

  const removeQueryParam = useCallback(async () => {
    const { pathname, query: routerQuery } = router;
    const params = new URLSearchParams(routerQuery as Record<string, string>);
    params.delete(query);
    await router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  }, [router, query]);

  return {
    queryParam,
    removeQueryParam,
    setQueryParam,
    getQueryParamAsURLSearchParams,
  };
};

const useIsAdvisor = () => {
  const { impersonateClientUserId } = useAdvisorStore();
  const { user } = useUser();
  const isAdvisor = useMemo(
    () => user && user["https://formefinancial.com/advisor"] === true,
    [user]
  );
  const isAdvisorPortal = useMemo(
    () => isAdvisor && !impersonateClientUserId,
    [isAdvisor, impersonateClientUserId]
  );
  return { isAdvisor, isAdvisorPortal };
};

const useNextEnvironment = () => {
  const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === "production";
  return {
    isProduction,
  };
};

const useFeatureFlag = () => {
  const { isProduction } = useNextEnvironment();
  const isFeatureFlagged = !isProduction;
  return {
    isFeatureFlagged,
  };
};

const usePrev = <V>(value: V) => {
  const ref = useRef<V>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const usePoFExperience = () => {
  const { user } = useUser();
  const { signupInfo, isLoading, isError, isIdle, isFetched } = useSignupInfo({
    options: { enabled: !!user },
  });
  const { isPoFExperience, setIsPoFExperience } = useStore();

  const isPoFFromSignupInfo =
    signupInfo && signupInfo.ABOUT_US_REFERRAL === "PHYSICIAN_ON_FIRE";

  startTransition(() => {
    if (isPoFFromSignupInfo !== isPoFExperience) {
      setIsPoFExperience(isPoFFromSignupInfo);
    }
  });

  if (!user) {
    return { isPof: false, isWaiting: false };
  }

  return {
    isPof: isPoFExperience || isPoFFromSignupInfo,
    isWaiting:
      isPoFFromSignupInfo === undefined &&
      (isIdle || (isLoading && !isError && !isFetched)),
  };
};

export {
  useIsAdvisor,
  useBreakpoints,
  useCareerStage,
  useLogout,
  useRouter,
  useRouterQueryParam,
  useNextEnvironment,
  useFeatureFlag,
  usePrev,
  usePoFExperience,
};
