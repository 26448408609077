import Sidebar from "@common/components/Sidebar";
import SidebarLink from "@common/components/Sidebar/SidebarLink";
import { HiOutlineCog, HiOutlineHome } from "react-icons/hi";
import { AdvisorRoutes } from "@common/constants";
import useStore from "@common/state";

const AdvisorSidebar = () => {
  const { isSidebarExpanded } = useStore();
  return (
    <Sidebar showAdvisorAccess={false}>
      <div className="flex flex-1 flex-col overflow-y-auto overflow-x-hidden scrollbar-none">
        <div className={"sidebar-section-header px-10 "}>
          <span
            className={`${
              isSidebarExpanded
                ? "sidebar-section-text-expanded"
                : "sidebar-section-text-collapsed"
            }`}
          >
            Wealth Advisor
          </span>
        </div>
        <SidebarLink
          href={AdvisorRoutes.Households}
          icon={<HiOutlineHome />}
          text="Households"
        />
        <SidebarLink
          href={AdvisorRoutes.Settings}
          icon={<HiOutlineCog />}
          text="Settings"
        />
      </div>
    </Sidebar>
  );
};

export default AdvisorSidebar;
