import {
  SignupFieldValues,
  SignupWizard,
  SignupFieldKeys,
  SIGNUP_FORM_SCHEMA,
  SignUpFormPages,
} from "@earned/wizard";
import * as React from "react";
import useStore from "@common/state";
import { useSignupStore } from "@modules/signup/state";
import { useNPIInfo, useSignupInfo } from "@modules/signup/queries";
import { useUpdateSignupInfo } from "@modules/signup/mutations";
import { useRouterQueryParam } from "@common/hooks";
import { ClientRoutes } from "@common/constants";
import { reach } from "yup";
import {
  useCreatedByAdvisor,
  useEqualMailingAddress,
} from "@modules/signup/hooks";

const SignupWizardController: React.FC = () => {
  const { setShowLogoutDialog } = useStore();
  const [isOpen, setIsOpen] = React.useState(true);
  const {
    fields: cache,
    defaultPage,
    setDefaultPage,
    setField,
  } = useSignupStore();
  const { signupInfo, isLoading } = useSignupInfo();
  const { mutateAsync, isLoading: isSubmitting } = useUpdateSignupInfo();
  const { getQueryParamAsURLSearchParams } = useRouterQueryParam("ref");
  /**
   * let cache override signup info to account for cases where user only partially completed wizard.
   * Since cache gets cleared after submit this is the only scenario in which the cache would be different from API
   */
  const signupInfoCache = React.useMemo(
    () => ({ ...signupInfo, ...cache }),
    [signupInfo, cache]
  );

  const handlePageChange = React.useCallback(
    (page: SignUpFormPages) => {
      setDefaultPage(page);
      if (defaultPage !== page) {
        window.heap?.track("Onboarding Page Change", { page });
      }
    },
    [setDefaultPage, defaultPage]
  );

  const handleSubmit = React.useCallback(
    async (fields: SignupFieldValues) => {
      await mutateAsync({ data: fields });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (useSignupStore as any).persist.clearStorage();
      setIsOpen(false);
      window.location.href =
        ClientRoutes.Overview +
        "?" +
        getQueryParamAsURLSearchParams({ replaceParams: true }).toString();
    },
    [mutateAsync, getQueryParamAsURLSearchParams]
  );

  const npiNumberSchema = reach(SIGNUP_FORM_SCHEMA, "NPI_NUMBER");

  const { data: npiInfo } = useNPIInfo({
    npiNumber: cache.NPI_NUMBER,
    enabled:
      npiNumberSchema.isValidSync(cache.NPI_NUMBER) === true &&
      !cache.NOT_A_DOCTOR,
  });

  React.useEffect(() => {
    if (npiInfo) {
      setField({
        key: SignupFieldKeys.SPECIALTY,
        value: npiInfo.SPECIALTY,
      });
      setField({
        key: SignupFieldKeys.SUBSPECIALTY,
        value: npiInfo.SUBSPECIALTY,
      });
    }
  }, [npiInfo, setField]);

  useEqualMailingAddress({
    signupInfoCache,
    isLoading,
    cb: () =>
      setField({
        key: SignupFieldKeys.SAME_AS_MAILING,
        value: true,
      }),
  });

  const createdByAdvisor = useCreatedByAdvisor();
  React.useEffect(() => {
    if (createdByAdvisor !== undefined) {
      setField({
        key: SignupFieldKeys.CREATED_BY_ADVISOR,
        value: createdByAdvisor,
      });
      if (createdByAdvisor === true) {
        setField({
          key: SignupFieldKeys.ABOUT_US_REFERRAL,
          value: undefined,
        });
        setField({
          key: SignupFieldKeys.INTERESTS,
          value: [],
        });
      }
    }
  }, [createdByAdvisor, setField]);

  const onFieldChange = React.useCallback(
    (field: { key: keyof SignupFieldValues; value: unknown }) => {
      setField(field);

      if (field.key === SignupFieldKeys.NOT_A_DOCTOR && field.value) {
        setField({
          key: SignupFieldKeys.NPI_NUMBER,
          value: "",
        });
        setField({
          key: SignupFieldKeys.CAREER_STAGE,
          value: null,
        });
        setField({
          key: SignupFieldKeys.SPECIALTY,
          value: "",
        });
        setField({
          key: SignupFieldKeys.SUBSPECIALTY,
          value: "",
        });
      }

      if (field.key === SignupFieldKeys.NPI_NUMBER) {
        setField({
          key: SignupFieldKeys.SPECIALTY,
          value: "",
        });
        setField({
          key: SignupFieldKeys.SUBSPECIALTY,
          value: "",
        });
      }
    },
    [setField]
  );

  return (
    <SignupWizard
      isOpen={isOpen}
      close={() => setShowLogoutDialog(true)}
      onDone={() => null}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      initialPage={defaultPage}
      onPageChange={handlePageChange}
      cache={signupInfoCache}
      isLoading={isLoading || !signupInfo}
      onFieldChange={onFieldChange}
      showAboutUsReferralQuestion={createdByAdvisor === false}
    />
  );
};

export default SignupWizardController;
