import { useUser } from "@auth0/nextjs-auth0";
import {
  ADDRESS_FIELDS,
  MAILING_ADDRESS_FIELDS,
  SignupFieldValues,
} from "@earned/wizard/dist";
import { isEqual, isNull, omitBy } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useIsomorphicLayoutEffect, useTimeout } from "usehooks-ts";
import { CREATED_BY_ADVISOR_CLAIM } from "@common/constants";

/**
 * Check if mailing address is equal to address, then set sameAsMailing to true only once
 */
interface EqualMailingAddressProps {
  signupInfoCache: SignupFieldValues;
  isLoading: boolean;
  cb: () => void;
}
const useEqualMailingAddress = ({
  signupInfoCache,
  isLoading,
  cb,
}: EqualMailingAddressProps) => {
  const savedCallback = useRef(cb);

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  const [mailingCheckDone, setMailingCheckDone] = useState(false);
  const isMailingEqual = useMemo(() => {
    // do not calculare if mailingCheckDone
    if (mailingCheckDone) return false;
    const clearNull = (data: any) => omitBy(data, isNull);

    const addressValues = clearNull(
      ADDRESS_FIELDS.map((field) => signupInfoCache[field])
    );
    const mailingAddressValues = clearNull(
      MAILING_ADDRESS_FIELDS.map((field) => signupInfoCache[field])
    );

    const equal = isEqual(addressValues, mailingAddressValues);
    return equal;
  }, [signupInfoCache, mailingCheckDone]);

  useEffect(() => {
    if (isMailingEqual && !mailingCheckDone) {
      savedCallback.current();
      setMailingCheckDone(true);
    }
  }, [isMailingEqual, mailingCheckDone]);

  // stop checking if we should enable SAME_AS_MAILING s after server cache is loaded
  const disable = () => {
    setMailingCheckDone(true);
  };
  useTimeout(disable, isLoading ? null : 2000);
};

const useCreatedByAdvisor = (): boolean | undefined => {
  const { user } = useUser();
  if (user) {
    return user[CREATED_BY_ADVISOR_CLAIM] === true;
  }
  return undefined;
};

export { useEqualMailingAddress, useCreatedByAdvisor };
