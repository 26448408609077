import { SignupFieldValues, SignUpFormPages } from "@earned/wizard";
import { StateCreator, create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";

interface SignupState {
  defaultPage: SignUpFormPages;
  setDefaultPage: (page: SignUpFormPages) => void;
  fields: SignupFieldValues;
  setField: <V>(field: { key: keyof SignupFieldValues; value: V }) => void;
  reset: () => void;
}

type SignupPersist = (
  config: StateCreator<SignupState>,
  options: PersistOptions<SignupState>
) => StateCreator<SignupState>;

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

const initialState = {
  defaultPage: SignUpFormPages.NAME,
  fields: {} as SignupFieldValues,
};
const useSignupStore = create<SignupState>(
  (persist as SignupPersist)(
    (set) => ({
      ...initialState,
      reset: () => set(initialState),
      setDefaultPage: (page) => set({ defaultPage: page }),
      setField: (field) =>
        set((state) => ({
          fields: {
            ...state.fields,
            [field.key]: field.value,
          },
        })),
    }),
    {
      name: "signup-wizard-state",
      version: 0.1,
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);

export { useSignupStore };
