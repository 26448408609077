import { CSSProperties, forwardRef, ReactNode } from "react";
import cx from "classnames";
import { HiOutlineLockClosed } from "react-icons/hi";
import { Typography } from "@earned/core";
import { themeTextColors } from "@common/constants";

const TopNavbar = forwardRef<
  HTMLDivElement,
  {
    isLocked?: boolean;
    title?: string;
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
  }
>(({ title, children, isLocked, style, className = "" }, ref) => {
  return (
    <div
      className={cx(
        "TopNavbar z-30 flex h-16 w-full flex-none flex-row items-center bg-white px-10 shadow-sm",
        className
      )}
      ref={ref}
      style={style}
    >
      {title ? (
        <div className="flex w-full flex-1 flex-row items-center justify-between">
          <Typography variant="body-xl" weight="medium" color="primary">
            {title}
          </Typography>
          {isLocked ? (
            <HiOutlineLockClosed size={24} color={themeTextColors.primary} />
          ) : (
            children
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
});
TopNavbar.displayName = "TopNavbar";

export default TopNavbar;
