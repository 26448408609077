import axios, { AxiosRequestConfig, Method } from "axios";

import { store as advisorStore } from "@modules/advisor/state";

type Data<T = null> = Record<string, string | boolean | number | null | T>;
type NestedData = Data<Data>;
type Headers = {
  [key: string]: string;
};
const request = async <T>(
  method: Method,
  url: string,
  data?: NestedData,
  headers?: Headers,
  skipImpersonation?: boolean
) => {
  const allHeaders = headers ?? {};
  const isReactNative = navigator.product === "ReactNative";
  if (isReactNative) {
    const Auth0 = (await import("react-native-auth0")).default;
    const auth0 = new Auth0({
      domain: `${process.env.AUTH0_BASE_URL_MOBILE}`,
      clientId: `${process.env.AUTH0_CLIENT_ID_MOBILE}`,
    });
    const credentials = await auth0.credentialsManager.getCredentials();
    allHeaders["Authorization"] = `Bearer ${credentials.idToken}`;
  }
  if (!skipImpersonation) {
    const { impersonateClientUserId } = advisorStore.getState();
    if (impersonateClientUserId) {
      allHeaders["x-forme-impersonate-client-user-id"] =
        impersonateClientUserId;
    }
  }

  let config: AxiosRequestConfig = {
    baseURL: process.env.PUBLIC_API_URL ?? "",
    headers: allHeaders,
    method,
    url,
  };

  if (data) {
    if (method === "GET") {
      config = {
        ...config,
        params: data,
      };
    } else {
      config = {
        ...config,
        data,
      };
    }
  }

  console.debug("API Request Config:", config);

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      console.debug("Request Error: ", error?.response);
      return Promise.reject(error);
    }
  );
  const res = await axios.request<T>(config);
  return res.data;
};

export default request;
