import AdvisorTeamMemberData from "@modules/advisory-team/data/team-members.json";
import {
  AdvisorName,
  AdvisorTeamMemberInfo,
} from "@modules/advisory-team/types";
import useStore from "@common/state";
import useMxStore from "@modules/mx/state";
import useAdvisorStore, { store as advisorStore } from "@modules/advisor/state";
import { usePersistStore as useDocVaultStore } from "@modules/docvault/state";
import { useSignupStore } from "@modules/signup/state";
import { useStore as useMobileStore } from "@mobile/context/state";

const resetPersistedStores = async (isReactNative = false) => {
  advisorStore.getState().reset();
  isReactNative && useSignupStore.getState().reset();
  isReactNative && useMobileStore.getState().reset();
  return Promise.all([
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (useMxStore as any).persist.clearStorage(),
    // NOTE: If the store is not used in React Native, it MUST be
    // disabled for ReactNative (!isReactNative).
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    !isReactNative && (useStore as any).persist.clearStorage(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (useSignupStore as any).persist.clearStorage(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (useAdvisorStore as any).persist.clearStorage(),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (useDocVaultStore as any).persist.clearStorage(),
  ]);
};

const advisorMeetingSetup = (
  advisor: Forme.Advisor | null | undefined,
  user: Forme.Claims | undefined,
  client: Forme.Client | undefined,
  showAdvisorDetails = true
) => {
  const calendlyUrl =
    (
      AdvisorTeamMemberData[
        advisor?.firstName as AdvisorName
      ] as AdvisorTeamMemberInfo
    )?.calendlyUrl ?? "https://calendly.com/earnedwealth/introduction-30";

  const meetingSetupProps = {
    url: `${calendlyUrl}?hide_gdpr_banner=1&hide_event_type_details=${
      showAdvisorDetails ? 0 : 1
    }`,
    prefill: {
      name: `${client?.firstName} ${client?.lastName}`,
      email: user?.email,
    },
  };

  return meetingSetupProps;
};

const last2DigitYearFormatter = (value: number) =>
  `'${String(value).slice(-2)}`;

const currencyFormatter = (
  value = 0,
  digits = 0,
  compact = false,
  includeSign = false
) =>
  `${value >= 0 ? (includeSign ? "+" : "") : "-"}$${Intl.NumberFormat("en", {
    notation: compact ? "compact" : "standard",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(Math.abs(value))}`;

const percentFormatter = (
  value = 0,
  digits = 0,
  compact = false,
  includeSign = false
) =>
  `${includeSign ? (value >= 0 ? "+" : "-") : ""}${Intl.NumberFormat("en", {
    notation: compact ? "compact" : "standard",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value * 100)}%`;

// Regex https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
const validateEmailFormat = (email: string) => {
  const isValid = email
    .toLowerCase()
    .trim()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  return isValid;
};

const abbreviateCurrency = (num: number, fixed: number) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  const b = num.toPrecision(2).split("e"), // get power
    k =
      b.length === 1
        ? 0
        : Math.floor(Math.min(parseFloat(b[1].slice(1)), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = parseFloat(c) < 0 ? c : Math.abs(parseFloat(c)), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
};

export {
  resetPersistedStores,
  validateEmailFormat,
  advisorMeetingSetup,
  last2DigitYearFormatter,
  currencyFormatter,
  percentFormatter,
  abbreviateCurrency,
};
