import Bill from "@images/bill.png";
import Matthew from "@images/matthew.jpeg";
import Myhanh from "@images/myhanh.png";
import Coleman from "@images/coleman.png";
import Hussein from "@images/hussein.png";

// TODO: don't match on first name - it does not scale
export const getAdvisorImage = (advisorFirstName: string) => {
  switch (advisorFirstName) {
    case "Coleman":
      return Coleman;
    case "Bill":
      return Bill;
    case "Hussein":
      return Hussein;
    case "Matthew":
      return Matthew;
    case "Myhanh":
    default:
      return Myhanh;
  }
};
